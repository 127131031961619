import { Select, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SortingRule } from 'react-table';
import { ReactTable, SearchInput, TableFooter } from '../../components';
import RegisterHeader from '../../components/RegisterHeader';
import { IResponseWithPagination } from '../../dtos/IPaginationDTO';
import useReactTableInstance, { Data } from '../../hooks/useReactTableInstance';
import api from '../../services/api';
import { exportData } from '../../utils/xlsx';

import { Container } from './styles';

interface ILog {
  id: string;
  user_id: string;
  user_email: string;
  date: string;
  module: string;
  description: string;
  user: {
    name: string;
    phone: string;
    company: string;
    email: string;
  };
}

const SORT_BY_STATE = [{ id: 'date', desc: true }];

const filterTypes = [
  {
    value: '0',
    label: 'Sem Filtro',
  },
  {
    value: '1',
    label: 'Módulo',
  },
  {
    value: '2',
    label: 'Pesquisar',
  },
];

const selectOptions = [
  {
    value: '',
    label: 'Sem filtro',
  },
  {
    value: 'QR CODE',
    label: 'QR Code',
  },
  {
    value: 'ORÇAMENTO',
    label: 'Orçamento',
  },
  {
    value: 'SUPORTE',
    label: 'Suporte',
  },
  {
    value: 'CERTIFICADOS',
    label: 'Certificados',
  },
];

const Statistics: React.FC = () => {
  const [queryTotalCount, setQueryTotalCount] = useState<number>(0);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize] = useState(20);
  const [querySortBy, setQuerySortBy] = useState<SortingRule<Data>[]>(
    SORT_BY_STATE,
  );
  const queryTotalPageCount = Math.ceil(queryTotalCount / queryPageSize);

  const [searchString, setSearchString] = useState('');
  const [selectedFilterType, setSelectedFilterType] = useState('0');

  const [logs, setLogs] = useState<ILog[]>([]);
  const [fullLogs, setFullLogs] = useState<ILog[]>([]);
  const [selectedModule, setSelectedModule] = useState('');

  const formattedLogs = useMemo(() => {
    return logs.length
      ? logs.map(log => ({
          email: log.user?.email,
          name: log.user?.name,
          company: log.user?.company,
          phone: log.user?.phone,
          date: format(new Date(log.date), 'yyyy-MM-dd hh:mm'),
          module: log.module,
          description: log.description,
        }))
      : [];
  }, [logs]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'email',
        Header: 'E-mail',
      },
      {
        accessor: 'name',
        Header: 'Nome',
      },
      {
        accessor: 'company',
        Header: 'Empresa',
      },
      {
        accessor: 'phone',
        Header: 'Telefone',
      },
      {
        accessor: 'date',
        Header: 'Data',
      },
      {
        accessor: 'module',
        Header: 'Módulo',
      },
      {
        accessor: 'description',
        Header: 'Descrição',
      },
    ];
  }, []);

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageIndex,
    pageOptions,
    prepareRow,
    previousPage,
    sortBy,
  } = useReactTableInstance({
    columns,
    data: formattedLogs,
    pagination: {
      pageCount: queryTotalPageCount,
      pageIndex: queryPageIndex,
      pageSize: queryPageSize,
    },
    sort: {
      sortBy: querySortBy,
    },
  });

  const handleChangeSelectModule = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedModule(event.target.value);
    },
    [],
  );

  const handleChangeSelectedFilterType = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSearchString('');
      setSelectedModule('');
      setSelectedFilterType(event.target.value);
    },
    [],
  );

  const handleExportData = useCallback(() => {
    let dataXLS = [];
    if (fullLogs.length > 0) {
      dataXLS = fullLogs;
    } else {
      dataXLS = logs;
    }

    const data = dataXLS.map(log => [
      log.user_email,
      log.user.name,
      log.user.company,
      log.user.phone,
      log.date,
      log.module,
      log.description,
    ]);

    exportData({
      columns: [
        'Email',
        'Nome',
        'Empresa',
        'Telefone',
        'Data',
        'Modulo',
        'Descricao',
      ],
      rows: data,
      filename: `relatorio_pedidos_${format(new Date(), 'yyyy-MM-dd')}`,
    });
  }, [fullLogs, logs]);

  const fetchLogs = useCallback(async () => {
    const sort_by = querySortBy[0].id;
    const order_by = querySortBy[0].desc ? 'DESC' : 'ASC';

    const response = await api.get<IResponseWithPagination<ILog>>('logs', {
      params: {
        limit: queryPageSize,
        offset: queryPageIndex * queryPageSize,
        sort_by,
        order_by,
        q: searchString,
        module: selectedModule,
      },
    });

    const { records, total_results } = response.data;

    setQueryTotalCount(total_results);
    setLogs(records);
  }, [
    queryPageIndex,
    queryPageSize,
    querySortBy,
    searchString,
    selectedModule,
  ]);

  const fetchAllLogs = useCallback(async () => {
    const sort_by = querySortBy[0].id;
    const order_by = querySortBy[0].desc ? 'DESC' : 'ASC';

    const response = await api.get<IResponseWithPagination<ILog>>('logs', {
      params: {
        limit: 1048576,
        offset: 0,
        sort_by,
        order_by,
        q: searchString,
        module: selectedModule,
      },
    });

    const { records } = response.data;
    setFullLogs(records);

    handleExportData();
  }, [handleExportData, querySortBy, searchString, selectedModule]);

  const debouncedFetch = useMemo(() => debounce(fetchLogs, 1000), [fetchLogs]);

  useEffect(() => {
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [debouncedFetch]);

  useEffect(() => {
    setQueryPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setQuerySortBy(sortBy.length ? sortBy : SORT_BY_STATE);
  }, [sortBy]);

  return (
    <Container>
      <RegisterHeader
        downloadDisabled={!logs.length}
        handleExportData={handleExportData}
        label="Exportar página para XSLX"
      />
      <RegisterHeader
        downloadDisabled={!logs.length}
        handleExportData={fetchAllLogs}
        label="Exportar log completo para XSLX"
      />
      <Stack direction={['column', 'row']} spacing={8} align="center" w="100%">
        <Stack spacing={4} direction={['column']} py={4} width="100%">
          <Text fontSize="lg">Filtrar por:</Text>
          <Select
            value={selectedFilterType}
            onChange={handleChangeSelectedFilterType}
            minW="250px"
          >
            {filterTypes.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Stack>
        {selectedFilterType === '1' && (
          <Stack spacing={4} direction={['column']} py={4} width="100%">
            <Text fontSize="lg">Módulo</Text>
            <Select
              value={selectedModule}
              onChange={handleChangeSelectModule}
              minW="250px"
            >
              {selectOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Stack>
        )}
        {selectedFilterType === '2' && (
          <SearchInput
            label="Filtre os logs"
            input={{
              placeholder: 'e-mail, nome, empresa, telefone e descrição',
              value: searchString,
              onChange: setSearchString,
            }}
          />
        )}
      </Stack>

      <ReactTable
        getTableBodyProps={getTableProps}
        headerGroups={headerGroups}
        rows={page}
        getTableProps={getTableProps}
        prepareRow={prepareRow}
      />
      <TableFooter
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
      />
    </Container>
  );
};

export default Statistics;
